.home-title-grid{
    display: grid;
    grid-template-columns: 1fr 60%;
    grid-auto-rows: 150px;
    column-gap: var(--sm);
    row-gap: var(--sm);
    justify-items: center;
}
.container.home-title-grid{
    /* padding: 30px 0; */
}
.home-title-grid .title{
    font-style: italic;
    align-self: flex-end;
    font-size: 2.6rem;
    line-height: 4.2rem;
}
.home-title-grid .subtitle{
    font-size: 2.6rem;
    line-height: 1;
    color: #9A1452;
    align-self: center;
}
.home-title-img{
    width: 100%;
    height: 100%;
    background-color: var(--background-color);
    grid-row: 1/3;
    grid-column: 2/3;
    background-repeat: no-repeat, repeat;
    background-position: center;
    background-size: contain;
}
#home-img{
    /* background-image: url("https://img.freepik.com/free-vector/eating-people-characters-restaurant-staff_82574-8799.jpg?size=626&ext=jpg"); */
}
@media only screen and (max-width: 768px) {
    .home-title-grid{
        grid-template-columns: 1fr;
        grid-auto-rows: auto;
        /* grid-template-rows: 1fr 1fr 1fr; */
        row-gap: 15px;
    }
    .container.home-title-grid{
        padding: 15px 0;
    }
    .home-title-img{
        height: 300px;
        grid-row: unset;
        grid-column: unset;
    }
    .home-title-grid .title{
        line-height: 3.5rem;
        font-style: normal;
    }
    .home-title-grid .subtitle{
        font-size: 2.25rem;
    }
}
@media only screen and (min-width: 768px) {
    .home-grid-item-left{
        height: auto;
        position: relative;
        padding-right: var(--lg);
    }
    .home-grid-item-left:after {
        content: '';
        position: absolute;
        top: 30px;
        right: 0;
        height: 95%;
        /* border-right: 1px solid rgb(192, 192, 192); */
    }
}
.container.home-content{
    padding-bottom: 30px;
}
.container h2{
    /* text-align: center; */
    margin: 0;
}
.home .list-tile{
    min-height: 120px;
    margin-bottom: 20px;
}
.list-tile{
    margin-left: 20px;
    column-gap: var(--md);
    /* align-items: center; */
}

.list-tile i{
    font-size: 6rem;
    color: rgb(55, 55, 180);
    height: 70px;
    width: 70px;
    display: grid;
    place-items: center;
}
.list-tile>div{
    /* align-self: center; */
}
.list-tile i.align-self-start{
    /* align-self: flex-start; */
}

.grid-item>.title{
    height: 70px;
    display: flex;
    align-items: center;
}

.list-tile h4{
    display: flex;
    align-items: center;
    height: 50px;
    color: #460A29;
    font-weight: 500;
    font-size: 24px;
    /* margin-bottom: var(--md); */
}
@media only screen and (max-width: 768px) {
    .list-tile{
        grid-template-columns: 1fr;
        justify-items: center;
        margin-left: unset;
    }
    .list-tile h4{
        /* width: 500px; */
        height: 50px;
        display: flex;
        /* justify-content: center; */
        text-align: center;
    }
}

.list-tile p{
    display: list-item;                                          
    list-style-type: disc; 
    margin-left: 20px;
}

.list-tile li{
    display: list-item;                                          
    list-style-type: disc; 
    margin-left: 20px;
    font-size: 1.5rem;
    line-height: 2rem;
}

.list-tile-content{
    width: 90%;
}
.container .home-content.title{
    margin: 30px 0;
}
.home-content.title{
    display: flex;
    flex-direction: column;
    align-items: center;

}
.home-content.title h2{
    margin-bottom: 0;
    text-align: center;
}
.home-content.title h5{
    text-align: center;
    color: #9A1452;
}
/* .list-tile .flex-column{
    align-items: left;
    justify-content: flex-start;
} */

.home .home-title-grid ol{
    font-size: 15px;
}

.list-tile h5{
    display: block;
    padding-left: 30px;
    color: #460A29;
    margin: 10px 0;
}

.list-tile ul{
    padding-left: 30px;
    margin: 20px 0;
}

.list-tile ul li{
    line-height: 2;
}

.list-tile table{
    width: 100%;
    border-collapse: none;
}

.list-tile table .mobile-header{
    display: none;
}

.list-tile table thead tr td{
    background-color: var(--trendy-pink);
    color: white;
    font-size: 20px;
}

.list-tile table thead tr .empty-cell{
    background-color: transparent;
}

.list-tile table tbody{
    font-size: 16px;
}

.list-tile table td{
    padding: 10px 30px;
    width: 200px
}

.list-tile table tr td{
    text-align: center;
}

.list-tile table tbody tr:nth-child(odd){
    background-color: #edeff4;
    color: #666;
}

.list-tile table tbody tr:nth-child(even){
    background-color: white;
    color: #666;
}

@media only screen and (max-width: 768px){
    .list-tile table tbody tr:nth-child(4n+1), .list-tile table tbody tr:nth-child(4n+2){
        background-color: #edeff4;
        color: #666;
    }

    .list-tile table tbody tr:nth-child(4n+3), .list-tile table tbody tr:nth-child(4n+4){
        background-color: white;
        color: #666;
    }

    .list-tile table tbody tr:nth-child(odd) td:nth-child(1){
        text-decoration: underline;
    }
}