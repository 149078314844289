.freedemo .img-title-container{
    position: relative;
    background-image: url("../../assets/freedemo_background.webp");
}
.freedemo .img-title-container h1{
    color: white;
    text-align: center;
}
.img-title-wrapper{
    height: 100%;
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px 0 70px 0;
}
.freedemo .img-title-container h1:nth-child(1){
    font-size: 16px;
}
.freedemo .container{
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.freedemo-description{
    margin: 60px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.freedemo h2{
    color: #9A1452;
}
.qr-code-container{
    
}
.qr-code-container h5, 
.qr-code-container p{
    text-align: center;
}

.qr-code-container p{
    font-size: 1.2rem;
}

.qr-code{
    height: 200px;
    width: 200px;
    background-position: center;
    background-size: contain;
}
.qr-code-wrap{
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    padding: 0 40px;
}
@media only screen and (max-width: 767px) {
    .qr-code-wrap{
        display: flex;
        padding: 20px 0;
    }
}
.register-btn{
    margin: 20px 0;
    height: 50px;
    width: 150px;
    border-radius: var(--sm);
    background-color: #b6286a;
    color: white;
    font-size: 1.8rem;
    text-align: center;
    transition: 0.5s;
}
.register-btn:hover{
    background-color: #771543;
    transition: 0.5s;
}
.tutorial-grid{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* display: grid; */
    /* grid-template-columns: 1fr 1fr; */
    /* grid-row: 80vh; */
    /* row-gap: 100px; */
    /* column-gap: 10px;
    align-items: center; */
}
.tutorial-grid .pic img{
    width: 100%;
    height: 700px;
    object-fit: contain;
    /* min-height: 200px; */
    /* vertical-align: middle; */
}
.tutorial-grid .pic{
    /* display: grid;
    place-items: center; */
    margin-bottom: 50px;
    margin-top: 10px;
}

.tutorial-grid .ipad-pic{
    /* height: 500px; */
    width: 660px;
    justify-self: center;
    margin: 40px auto 80px;
}

.tutorial-grid .ipad-pic>img{
    height: 470px;
    width: 640px;
}
.freedemo-wrapper h4{
    margin: 10px 0;
}
.tutorial-grid .txt{
    display: flex;
    justify-self: center;
}
.tutorial-grid .txt>h3{
    margin-right: 12px;
}
.tutorial-grid h3{
    color: #9A1452;
    font-size: 3rem;
    line-height: 4rem;
}
.tutorial-grid p{
    font-size: 1.5rem;
    line-height: 3rem;
}
@media only screen and (max-width: 992px) {
    .tutorial-grid .ipad-pic{
        height: 500px;
        width: 650px;
    }
    .tutorial-grid .pic img{
        height: 600px;
    }
    .tutorial-grid .ipad-pic img{
        height: 100%;
        width: 100%;
    }
}


@media only screen and (max-width: 767px) {
    .tutorial-grid{
        grid-template-columns: 1fr;
        row-gap: 60px;
    }
    .tutorial-grid .ipad-pic{
        height: 450px;
        width: 600px;
        /* margin-top: unset; */
    }
    .tutorial-grid .pic{
        height: 450px;
        width: 600px;
    }
    .tutorial-grid .pic img{
        height: 100%;
        width: 100%;
    }
    .tutorial-grid h3{
        font-size: 2.25rem;
        line-height: 3rem;
    }
    .tutorial-grid p{
        font-size: 1.5rem;
        line-height: 2rem;
    }
    .tutorial-grid .txt{
        justify-self: start;
    }
}
@media only screen and (max-width: 600px) {
    .tutorial-grid .ipad-pic{
        width: 300px;
        height: 230px;
    }
    .tutorial-grid .pic{
        height: 260px;
        width: 340px;
    }
    .tutorial-grid .pic img{
        height: 100%;
        width: 100%;
    }
}
.download-on-appstore{
    height: 50px;
    width: 100px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/caterat-pos.appspot.com/o/resources%2Fappstore.svg?alt=media&token=a5e9b5ba-6830-449e-b288-eea378fa3874");
}
.download-on-playstore{
    height: 50px;
    width: 100px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/caterat-pos.appspot.com/o/resources%2Fplaystore.svg?alt=media&token=36de0cf6-fc5c-4205-bf89-d6bc5c1a2514");
}
.button-container{
    width: 100%;
    text-align: center;
}