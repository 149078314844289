.content-grid-5{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.contactus{
    height: 100%;
}
.contactus .container{
    padding: 30px;
    /* display: flex;
    align-items: center; */
}
.contactus .container h2{
    font-size: 2.25rem;
}
.address-map{
    width: 100%;
    height: 450px;
}
@media only screen and (max-width: 768px) {
    .content-grid-5{
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 30px;
    }
    .contactus .container h2{
        font-size: 1.9rem;
    }
}


