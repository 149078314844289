.app{
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;

}

.app.show-overlay{
    overscroll-behavior: contain;
}
.header{
    /* border-bottom: 1px solid #E2E8F0; */
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    /* z-index: 2; */
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
}
.caterat-logo{
    height: 100px;
    width: 200px;
    background-repeat: no-repeat, repeat;
    background-position: center;
    background-size: contain;
    align-self: center;
    cursor: pointer;
    /* background-image: url("https://firebasestorage.googleapis.com/v0/b/caterat-pos.appspot.com/o/public-website%2FCaterat%20Logo%20Outlined.svg?alt=media&token=2b13fd22-b3f7-4423-b6be-048f1974a8f0"); */
}
.nav-contact{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: var(--sm);
    height: 100px;
    text-align: center;
}
.nav-contact i{
    cursor: pointer;
    font-size: 5rem;
}
.nav-contact .info{
    cursor: pointer;
}
.navbar {
    height: var(--header-height);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 1rem 1.5rem;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
}

.nav-menu-btn {
    display: none;
    text-align: center;
    width: 35px;
    height: 35px;
    border: 2px solid black;
    z-index: 10;
}
.nav-close-btn{
    width: 40px;
    height: 40px;
    text-align: center;
}

.nav-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
/* .nav-contact{
    align-self: flex-end;
    padding: 1rem 0;
    font-size: 1.5rem;
} */

.nav-item {
    margin-left: 3.5rem;
}

.nav-link{
    font-size: 1.6rem;
    font-weight: 500;
    /* color: #475569; */
    color: black;
}
#login-btn{
    color:#9A1452;
}
.footer .nav-link{
    color: white!important;
}

.nav-link:hover{
    color: var(--primary-color);
}

.nav-logo {
    align-self: center;
    font-size: 2.1rem;
    font-weight: 500;
    color: var(--primary-color);
}
.backdrop{
    
    display: none;
    position: fixed;
    top:0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background-color: transparent;
    background-color: rgba(94, 94, 94, 0.473);
    -moz-opacity: 0.6;
    /* Mozilla */
    opacity: 0.8;
    z-index: 30;
}
.backdrop.show-overlay{
    display: block;
}
.mobile-nav-menu-overlay{
    /* display: none; */
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    left: 100%;
    /* background-color: white; */
    background-color: transparent;
    z-index: 40;
    transition: ease-out 0.3s;
    overflow-x: hidden;
    display: flex;
}
.mobile-nav-menu-overlay.show-overlay{
    /* width: 60vw; */
    left: 0;
    /* right: 0; */
}
.backdrop-container{
    background-color: transparent;
    width: 60vw;
    z-index: 50;
}
.mobile-nav-menu-container{
    width: 40vw;
    background-color: white;
    z-index: 50;
}

.main{
    /* min-height: calc(100vh - var(--header-height) - var(--footer-height));;
    max-height: auto; */
    width: 100%;
    /* max-width: 992px; */
    flex: 1 0 auto;
    background-color: var(--background-color);
    /* background-color: rgb(238, 238, 238); */
}
.container{
    width: 100%;
    max-width: 992px;
    margin: auto;
    padding: 0 var(--md);
}
.divider{
    width: 100%;
    border-top: 3px solid #460A29;
}
.footer{
    height: var(--footer-height);
    width: 100vw;
    color: white;
    background-color: var(--trendy-pink);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}
.footer .nav-link{
    color: white;
    font-size: 1.3rem;
}

.footer p{
    font-weight: 500;
}
.mobile-footer{
    display: none;
    position: fixed;
    bottom: 0;
    height: 80px;
    width: 100%;
    background-color: white;
    /* background-color: transparent; */
    z-index: 1;
}
.footer-content{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.footer-btn{
    width: 100%;
    height: 80px;
    /* display: grid;
    place-items: center; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
}
.footer-btn>i{
    margin-bottom: 10px;
}
.sized{
    /* background-color: teal; */
    height: 1000px;
}
@media only screen and (max-width: 768px) {
    .container{
        padding-bottom: 80px;
    }
    .mobile-nav-menu {
        position: absolute;
        top: 50px;
        right: 0;
        display: flex;
        flex-direction: column;
        /* justify-content: space-between; */
        align-items: flex-end;
        /* width: 100%; */
        border-radius: 10px;
        text-align: end;
        transition: 0.3s;
        padding-right: 20px;
        overflow: hidden;
    }
    .mobile-nav-menu.close{
        display: none;
    }
    .nav-menu{
        display: none;
    }
    .main{
        padding-bottom: 80px;
    }

    .nav-item {
        height: 60px;
    }
    .nav-close-btn{
        width: auto;
    }
    .nav-menu-btn {
        position: fixed;
        right: 20px;
        top: 50px;
        display: block;
        cursor: pointer;
    }
    .nav-contact{
        display: none;
    }
    .footer{
        display: none;
    }
    .mobile-footer{
        display: block;
    }
}
@media only screen and (min-width: 769px) {
    .mobile-nav-menu-overlay{
        display: none;
    }
}
.content-grid-1{
    width: 100%;
    display: grid;
    /* margin: 5px; */
    padding: 5px;
    grid-template-columns: repeat(auto-fit, minmax(384px, 1fr));
    /* grid-gap: 1rem; */
    row-gap: 2.5rem;
    column-gap: 1rem;
}
@media only screen and (max-width: 768px) {
    .content-grid-1{
        /* grid-template-columns: repeat(auto-fit, minmax(200px, 384px)); */
    }
}
@media only screen and (max-width: 384px) {
    .content-grid-1{
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
}
/* .content, .word, .pic{
    background-color: teal;
    color: white;
    font-size: 2rem;
    height: 50px;
    max-width: calc(100vw - 10px);
} */


.word{ 
    width: 100%;
    background-color: thistle;
}
.pic{ 
    width: 100%;
    /* background-color:lightblue; */
}
.content-grid-2{
    /* display: flex;
    flex-direction: column; */
    /* padding: 5px; */
    /* gap: 30px; */
}
.content-grid-2-row{
    display: flex;
    margin-bottom: 5px;
    /* gap: 1rem; */
}
@media only screen and (max-width: 768px) {
    .content-grid-2{
        display: flex;
        flex-direction: column;
        /* gap: 50px; */
    }
    .content-grid-2-row{
        flex-direction: column;
    }
    .content-grid-2-row:nth-child(even){
        flex-direction: column-reverse;
    }
}
a:hover{
    cursor: pointer;
}

.banner{
    background-color: #ffffad;
    font-size: 2rem;
    line-height: 1;
    color: #f93416;
    text-align: center;
    padding: 20px
}