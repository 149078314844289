.pricing .container{
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}
.pricing .img-title-container{
    position: relative;
    background-image: url("../../assets/pricing_background.webp");
}
.pricing .img-title-container h1{
    color: white;
    text-align: center;
}
.img-title-wrapper{
    height: 100%;
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px 0 70px 0;
}
.pricing .img-title-container h1:nth-child(1){
    font-size: 16px;
}
.plan-btn{
    display: none;
}

.plan-btn div{
    border: 1px solid #9A1452;
    font-size: 2rem;
    line-height: 50px;
    text-align: center;
    height: 50px;
    /* width: 200px; */
}
.pricing-label.first{
    border-top-left-radius: var(--sm);
    border-bottom-left-radius: var(--sm);
}

.pricing-label.last{
    border-top-right-radius: var(--sm);
    border-bottom-right-radius: var(--sm);
}
input[type="radio"] {  
    display:none;                      
}
input[type="radio"] + label span{
    display:inline-block;
    border: 1px solid #9A1452;
    font-size: 2rem;
    line-height: 50px;
    text-align: center;
    height: 50px;
    width: 150px;          
    cursor: pointer;
    /* margin:1px 0px 0 -30px; */
}
input[type="radio"]:checked + label span{
    background-color: #9A1452;
    color: white;
}
.plan-card{
    width:300px;
    min-height: 300px;
    background-color: white;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    /* box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.075); */
    border-radius: var(--sm);
    transition: box-shadow .3s;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    text-align: center;
    gap: var(--md);
    padding: var(--md);
}
.plan-card h3{
    font-size: 2.2rem;
    line-height: 4rem;
}
.plan-card div{
    flex: 1;
}
/* .plan-card:hover{
    box-shadow: 0 10px 20px -2px rgba(0, 0, 0, 0.075)
} */
.plan-card i{
    font-size: 6rem;
}
.plan-card p{
    font-size: 1.6rem;
    line-height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.4rem 0;
}
.plan-card p>i{
    font-size: 1.8rem;
    margin-left: 0.5rem;
    line-height: 2.5rem;
    vertical-align: middle;
}

.plan-card-price h6{
    font-family: "Lato",sans-serif;
    letter-spacing: -2px;
    color: #525252;
    font-weight: 400;
    font-size: 1.4rem;
}

.plan-card .dollar-sign {
    display: inline-block;
    font-family: "Lato",sans-serif;
    font-size: 18px;
    font-weight: 500;
    vertical-align: top;
}
.plan-card .currency {
    font-family: "Lato",sans-serif;
    font-size: 36px;
    font-weight: 500;
    letter-spacing: -2px;
    line-height: 36px;
    padding: 0;
    vertical-align: middle;
}
.plan-card .year, .month, .interval{
    font-family: "Lato",sans-serif;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 3px;
    vertical-align: bottom;
}
.pricing h2{
    color: #9A1452;
}
.plan-card-btn{
    /* margin: 20px 0; */
    margin-top: 5px;
    margin-bottom: 20px;
    height: 50px;
    width: 150px;
    border-radius: var(--sm);
    background-color: #9A1452;
    color: white;
    font-size: 1.8rem;
}
/* .pricing .list-item{
    font-size: 1.875rem;
        line-height: 2.25rem;
} */
.plan-card-container{
    display: flex;
    gap: 5rem;
    padding: 20px 0;
}
@media only screen and (max-width: 768px) {
    .plan-card-container{
        flex-direction: column;
        gap: 2rem;
    }
    .plan-btn{
        display: flex;
    }
    .desktop-only{
        display: none;
    }
}
.plan-card .highlight{
    color: #9A1452;
}
.plan-card .remark{
    font-size: 1.2rem;
    cursor: pointer;
    margin-bottom: 0px;
    margin-top: 20px;
    color: grey;
}
.tvp-description{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    /* margin: 20px 0; */
}
.tvp-description a{
    color: #373d4a;
    text-decoration: underline;
}

.tvp-description h2{
    text-align: center;
}
.tvp-description h4{
    text-align: center;
    line-height: 3rem;
}
#pricing-remark{
    text-align: center;
    margin-top: 10px;
}
.space-separator{
    height: 15px;
}