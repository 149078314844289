@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,500;1,400&display=swap');
*{
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
    margin: 0;
    padding: 0;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
@font-face {
    font-family: 'helvetica-w01-light';
    src: url('./assets/Helvetica\ W01\ Light.woff.ttf');
    font-weight: bold;
  }
*::-webkit-scrollbar {
    display: none;
}

button{
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    text-align: inherit;
    background: transparent;
    outline: unset;
    /* inherit font & color from ancestor */
    color: inherit;
    font: inherit;

    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    line-height: normal;

    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none;

    user-select: none;
}

button:hover{
    cursor: pointer;
}
button:active{
    transform: scale(0.95);
}

input[type="radio"] {
    -webkit-appearance: checkbox; /* Chrome, Safari, Opera */
    -moz-appearance: checkbox;    /* Firefox */
    -ms-appearance: checkbox;     /* not currently supported */
}
body{
    color:  #373d4a;
    font-family: 'helvetica-w01-light', 'Roboto', sans-serif;
}

html {
    font-size: 62.5%;
}

li {
    list-style: none;
}

a {
    text-decoration: none;

}
*{  
    --background-color: white;
    --box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.075);
    --header-height: 150px;
    --footer-height: 150px;
    --primary-color: #662483;
    --subtitle-color: #662483;
    --seance: #642483;
    --trendy-pink: #7f608b;
    --mine-shaft: #343434;
    --salt-box: #645c6f;
    --scarpa-flow: #58505d;
    --black: #050505;
    --seance: #6c2484;
    --abbey: #4c4c54;
    --cod-gray: #1c1c1c;
    --xxs: 0.2rem;
    --xs: 0.375rem;
    --sm: 0.75rem;
    --md: 1rem;
    --lg: 1.25rem;
    --xl: 1.5rem;
}

h1, h2, h3, h4,h5, h6, p{
 font-weight: normal;
 /* color: var(--black); */
}
h1{
    font-size: 3rem;
    line-height: 1;
}
h2{
    font-size: 3rem;
    line-height: 1;
}
h3{
    font-size: 2.25rem;
    line-height: 2.5rem;
}
h4{
    font-size: 1.875rem;
    line-height: 2.25rem;
}
h5{
    font-size: 1.5rem;
    line-height: 2rem;
}
h6{
    font-size: 1.5rem;
    line-height: 2rem;
}
p{
    font-size: 1.5rem;
    line-height: 2rem;
}
@media only screen and (max-width: 768px) {
    h1{
        font-size: 2.25rem;
        line-height: 2.5rem;
    }
    h2{
        font-size: 2.25rem;
        line-height: 2.5rem;
    }
    h3{
        font-size: 1.875rem;
        line-height: 2.25rem;
    }
    h4{
        font-size: 1.8rem;
        line-height: 2rem;
    }
    h5{
        font-size: 1.5rem;
        line-height: 2rem;
    }
    h6{
        font-size: 1.25rem;
        line-height: 1.75rem;
    }
    p{
        font-size: 1.25rem;
        line-height: 1.75rem;
    }
}
input{
    outline: none;
}
.block{
    display: block;
}
.flex-column{
    display: flex;
    flex-direction: column;
}

.flex-row{
    display: flex;
    flex-direction: row;
}

.justify-content-between{
    justify-content: space-between;
}
.align-self-start{
    align-self: flex-start;
}
.align-self-center{
    align-self: center;
}
.italic{
    font-style: italic;
}
.bold{
    font-weight: 500;
}
.height\:full{
    height: 100%;
}
