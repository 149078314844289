.img-title-container{
    background-repeat: no-repeat, repeat;
    background-position: center;
    background-size: cover;
    height: 200px;
    width: 100%;
    display: grid;
    place-items: center;
}
.features .img-title-container{
    position: relative;
    background-image: url("https://static.wixstatic.com/media/nsplsh_e22538ff50a6492aa1ab6013b090f06a~mv2.jpg/v1/fill/w_1164,h_245,al_c,q_80,usm_0.66_1.00_0.01/nsplsh_e22538ff50a6492aa1ab6013b090f06a~mv2.webp");
}
.features .img-title-container:after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.3;
    z-index: 1;
}
.features .img-title-container h1{
    color: white;
    text-align: center;
}
.img-title-wrapper{
    height: 100%;
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px 0 70px 0;
}
.features .img-title-container h1:nth-child(1){
    font-size: 16px;
}
/* .features>h2{
    margin: 4.5rem 0;
    text-align: center;
} */
.features-title{
    margin: 4.5rem 0;
    text-align: center;
    font-size: 24px;
}

.content-grid-2 .word-container{
    /* width: 100%; */
    flex: 430px 0;
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    /* justify-content: center; */
    /* place-items: center; */
}

.content-grid-2 .word-container h2{
    font-size: 20px;
    margin: 0;
    margin-bottom: 20px;
    color: #9A1452;
}
.content-grid-2 .content-grid-2-row{
    margin: 50px 0;
    /* gap: 80px; */
    justify-content: space-between;
}
.content-grid-2 .content-grid-2-row:nth-child(2){
    margin-top: 0;
}
.features .content-grid-2-row .word-container>div{
    width: 80%;
}
@media only screen and (max-width: 768px) {
    .features .content-grid-2-row .word-container>div{
        width: 100%;
    }
    .features .content-grid-2 h2{
       
        margin-bottom: 20px;
        /* margin: 20px 0; */
        text-align: center;
    }
    .features-title{
        margin: 0 0;
        margin-top: 50px;
        /* margin-bottom: 20px; */
    }
    .features .content-grid-2 .content-grid-2-row{
        margin: 20px 0;
        /* justify-content: center; */
    }
    .features .content-grid-2-row .word-container{
        max-height: 270px;
        max-width: 80%;
        align-self: center;
        /* justify-content: center; */
    }
    .features .content-grid-2-row .word-container h2{
        text-align: center;
    }
    .container h2 {
        /* margin-bottom: 10px; */
    }
}
.title-remark{
    font-size: 10px;
    color: black;
}
.list-item{
    display: list-item;                                          
    list-style-type: disc; 
    margin-left: 20px;
}
.image-container, .gif-container, .iphone-container{
    background-repeat: no-repeat, repeat;
    background-position: center;
    background-size: contain;
}
.image-container{
    background-color: var(--background-color);
    flex: 430px 0;
    position: relative;
    height: 328px;
    width: 420px;
}
.ipad-border{
    background-color: rgba(39, 38, 38, 0.938);
    border: rgba(39, 38, 38, 0.938) solid 10px;
    border-radius: 10px;
    box-shadow: 2px 2px 4px 1px rgba(120,120,120,1);
}
.iphone-container{
    margin: auto;
    width: 140px;
    height: 320px;
    background-color: rgba(39, 38, 38, 0.938);
    border: rgba(39, 38, 38, 0.938) solid 8px;
    border-radius: 15px;
    box-shadow: 2px 2px 4px 1px rgba(120,120,120,1);
}
@media only screen and (max-width: 768px) {
    .image-container{
        max-height: 300px;
        width: 400px;
        align-self: center;
    }
    .iphone-container{
        width: 120px;
        height: 260px;
    }
}
@media only screen and (max-width: 600px) {
    .image-container{
        max-height: 230px;
        width: 300px;
    }
}
/* @media only screen and (min-width: 769px) {
    .content-grid-2-row:nth-child(even) .image-container{
        background-position:left;
    }
    .content-grid-2-row:nth-child(even) .word-container{
        justify-content: flex-end;
    }
    .content-grid-2-row:nth-child(odd) .image-container{
        background-position:right;
    }
    .content-grid-2-row:nth-child(odd) .word-container{
        justify-content: flex-start;
    }
} */

#menu{
    background-image: url("https://firebasestorage.googleapis.com/v0/b/caterat-pos.appspot.com/o/%E9%A4%90%E9%A3%B2%E7%B3%BB%E7%B5%B1%E9%9B%BB%E5%AD%90%E9%A4%90%E7%89%8C.png?alt=media&token=d2f0b6a4-3648-4861-b9ba-616cdcf3950e");
}
#floor-plan{
    background-image: url("https://firebasestorage.googleapis.com/v0/b/caterat-pos.appspot.com/o/Ipad.png?alt=media&token=a71e880e-2149-45df-906b-1cfbc8fbdb7a");
}
#floor-plan-gif{
    background-image: url("https://firebasestorage.googleapis.com/v0/b/caterat-pos.appspot.com/o/%E9%A4%90%E9%A3%B2%E7%B3%BB%E7%B5%B1%E5%BA%A7%E4%BD%8D%E5%AE%89%E6%8E%92.gif?alt=media&token=982b3988-2465-4d60-af3b-5f1b172b883a");
}