@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,500;1,400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{ /* Safari/Chrome, other WebKit */    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
    margin: 0;
    padding: 0;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
@font-face {
    font-family: 'helvetica-w01-light';
    src: url("/static/media/Helvetica W01 Light.woff.5cc7e058.ttf");
    font-weight: bold;
  }
*::-webkit-scrollbar {
    display: none;
}

button{
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    text-align: inherit;
    background: transparent;
    outline: unset;
    /* inherit font & color from ancestor */
    color: inherit;
    font: inherit;

    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    line-height: normal;

    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none;

    -webkit-user-select: none;

        -ms-user-select: none;

            user-select: none;
}

button:hover{
    cursor: pointer;
}
button:active{
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
}

input[type="radio"] {
    -webkit-appearance: checkbox; /* Chrome, Safari, Opera */
    -moz-appearance: checkbox;    /* Firefox */
    -ms-appearance: checkbox;     /* not currently supported */
}
body{
    color:  #373d4a;
    font-family: 'helvetica-w01-light', 'Roboto', sans-serif;
}

html {
    font-size: 62.5%;
}

li {
    list-style: none;
}

a {
    text-decoration: none;

}
*{  
    --background-color: white;
    --box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.075);
    --header-height: 150px;
    --footer-height: 150px;
    --primary-color: #662483;
    --subtitle-color: #662483;
    --seance: #642483;
    --trendy-pink: #7f608b;
    --mine-shaft: #343434;
    --salt-box: #645c6f;
    --scarpa-flow: #58505d;
    --black: #050505;
    --seance: #6c2484;
    --abbey: #4c4c54;
    --cod-gray: #1c1c1c;
    --xxs: 0.2rem;
    --xs: 0.375rem;
    --sm: 0.75rem;
    --md: 1rem;
    --lg: 1.25rem;
    --xl: 1.5rem;
}

h1, h2, h3, h4,h5, h6, p{
 font-weight: normal;
 /* color: var(--black); */
}
h1{
    font-size: 3rem;
    line-height: 1;
}
h2{
    font-size: 3rem;
    line-height: 1;
}
h3{
    font-size: 2.25rem;
    line-height: 2.5rem;
}
h4{
    font-size: 1.875rem;
    line-height: 2.25rem;
}
h5{
    font-size: 1.5rem;
    line-height: 2rem;
}
h6{
    font-size: 1.5rem;
    line-height: 2rem;
}
p{
    font-size: 1.5rem;
    line-height: 2rem;
}
@media only screen and (max-width: 768px) {
    h1{
        font-size: 2.25rem;
        line-height: 2.5rem;
    }
    h2{
        font-size: 2.25rem;
        line-height: 2.5rem;
    }
    h3{
        font-size: 1.875rem;
        line-height: 2.25rem;
    }
    h4{
        font-size: 1.8rem;
        line-height: 2rem;
    }
    h5{
        font-size: 1.5rem;
        line-height: 2rem;
    }
    h6{
        font-size: 1.25rem;
        line-height: 1.75rem;
    }
    p{
        font-size: 1.25rem;
        line-height: 1.75rem;
    }
}
input{
    outline: none;
}
.block{
    display: block;
}
.flex-column{
    display: flex;
    flex-direction: column;
}

.flex-row{
    display: flex;
    flex-direction: row;
}

.justify-content-between{
    justify-content: space-between;
}
.align-self-start{
    align-self: flex-start;
}
.align-self-center{
    align-self: center;
}
.italic{
    font-style: italic;
}
.bold{
    font-weight: 500;
}
.height\:full{
    height: 100%;
}

.home-title-grid{
    display: grid;
    grid-template-columns: 1fr 60%;
    grid-auto-rows: 150px;
    grid-column-gap: var(--sm);
    -webkit-column-gap: var(--sm);
            column-gap: var(--sm);
    grid-row-gap: var(--sm);
    row-gap: var(--sm);
    justify-items: center;
}
.container.home-title-grid{
    /* padding: 30px 0; */
}
.home-title-grid .title{
    font-style: italic;
    align-self: flex-end;
    font-size: 2.6rem;
    line-height: 4.2rem;
}
.home-title-grid .subtitle{
    font-size: 2.6rem;
    line-height: 1;
    color: #9A1452;
    align-self: center;
}
.home-title-img{
    width: 100%;
    height: 100%;
    background-color: var(--background-color);
    grid-row: 1/3;
    grid-column: 2/3;
    background-repeat: no-repeat, repeat;
    background-position: center;
    background-size: contain;
}
#home-img{
    /* background-image: url("https://img.freepik.com/free-vector/eating-people-characters-restaurant-staff_82574-8799.jpg?size=626&ext=jpg"); */
}
@media only screen and (max-width: 768px) {
    .home-title-grid{
        grid-template-columns: 1fr;
        grid-auto-rows: auto;
        /* grid-template-rows: 1fr 1fr 1fr; */
        grid-row-gap: 15px;
        row-gap: 15px;
    }
    .container.home-title-grid{
        padding: 15px 0;
    }
    .home-title-img{
        height: 300px;
        grid-row: unset;
        grid-column: unset;
    }
    .home-title-grid .title{
        line-height: 3.5rem;
        font-style: normal;
    }
    .home-title-grid .subtitle{
        font-size: 2.25rem;
    }
}
@media only screen and (min-width: 768px) {
    .home-grid-item-left{
        height: auto;
        position: relative;
        padding-right: var(--lg);
    }
    .home-grid-item-left:after {
        content: '';
        position: absolute;
        top: 30px;
        right: 0;
        height: 95%;
        /* border-right: 1px solid rgb(192, 192, 192); */
    }
}
.container.home-content{
    padding-bottom: 30px;
}
.container h2{
    /* text-align: center; */
    margin: 0;
}
.home .list-tile{
    min-height: 120px;
    margin-bottom: 20px;
}
.list-tile{
    margin-left: 20px;
    grid-column-gap: var(--md);
    -webkit-column-gap: var(--md);
            column-gap: var(--md);
    /* align-items: center; */
}

.list-tile i{
    font-size: 6rem;
    color: rgb(55, 55, 180);
    height: 70px;
    width: 70px;
    display: grid;
    place-items: center;
}
.list-tile>div{
    /* align-self: center; */
}
.list-tile i.align-self-start{
    /* align-self: flex-start; */
}

.grid-item>.title{
    height: 70px;
    display: flex;
    align-items: center;
}

.list-tile h4{
    display: flex;
    align-items: center;
    height: 50px;
    color: #460A29;
    font-weight: 500;
    font-size: 24px;
    /* margin-bottom: var(--md); */
}
@media only screen and (max-width: 768px) {
    .list-tile{
        grid-template-columns: 1fr;
        justify-items: center;
        margin-left: unset;
    }
    .list-tile h4{
        /* width: 500px; */
        height: 50px;
        display: flex;
        /* justify-content: center; */
        text-align: center;
    }
}

.list-tile p{
    display: list-item;                                          
    list-style-type: disc; 
    margin-left: 20px;
}

.list-tile li{
    display: list-item;                                          
    list-style-type: disc; 
    margin-left: 20px;
    font-size: 1.5rem;
    line-height: 2rem;
}

.list-tile-content{
    width: 90%;
}
.container .home-content.title{
    margin: 30px 0;
}
.home-content.title{
    display: flex;
    flex-direction: column;
    align-items: center;

}
.home-content.title h2{
    margin-bottom: 0;
    text-align: center;
}
.home-content.title h5{
    text-align: center;
    color: #9A1452;
}
/* .list-tile .flex-column{
    align-items: left;
    justify-content: flex-start;
} */

.home .home-title-grid ol{
    font-size: 15px;
}

.list-tile h5{
    display: block;
    padding-left: 30px;
    color: #460A29;
    margin: 10px 0;
}

.list-tile ul{
    padding-left: 30px;
    margin: 20px 0;
}

.list-tile ul li{
    line-height: 2;
}

.list-tile table{
    width: 100%;
    border-collapse: none;
}

.list-tile table .mobile-header{
    display: none;
}

.list-tile table thead tr td{
    background-color: var(--trendy-pink);
    color: white;
    font-size: 20px;
}

.list-tile table thead tr .empty-cell{
    background-color: transparent;
}

.list-tile table tbody{
    font-size: 16px;
}

.list-tile table td{
    padding: 10px 30px;
    width: 200px
}

.list-tile table tr td{
    text-align: center;
}

.list-tile table tbody tr:nth-child(odd){
    background-color: #edeff4;
    color: #666;
}

.list-tile table tbody tr:nth-child(even){
    background-color: white;
    color: #666;
}

@media only screen and (max-width: 768px){
    .list-tile table tbody tr:nth-child(4n+1), .list-tile table tbody tr:nth-child(4n+2){
        background-color: #edeff4;
        color: #666;
    }

    .list-tile table tbody tr:nth-child(4n+3), .list-tile table tbody tr:nth-child(4n+4){
        background-color: white;
        color: #666;
    }

    .list-tile table tbody tr:nth-child(odd) td:nth-child(1){
        text-decoration: underline;
    }
}
.app{
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;

}

.app.show-overlay{
    -ms-scroll-chaining: none;
        overscroll-behavior: contain;
}
.header{
    /* border-bottom: 1px solid #E2E8F0; */
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    /* z-index: 2; */
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
}
.caterat-logo{
    height: 100px;
    width: 200px;
    background-repeat: no-repeat, repeat;
    background-position: center;
    background-size: contain;
    align-self: center;
    cursor: pointer;
    /* background-image: url("https://firebasestorage.googleapis.com/v0/b/caterat-pos.appspot.com/o/public-website%2FCaterat%20Logo%20Outlined.svg?alt=media&token=2b13fd22-b3f7-4423-b6be-048f1974a8f0"); */
}
.nav-contact{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    grid-gap: var(--sm);
    gap: var(--sm);
    height: 100px;
    text-align: center;
}
.nav-contact i{
    cursor: pointer;
    font-size: 5rem;
}
.nav-contact .info{
    cursor: pointer;
}
.navbar {
    height: var(--header-height);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 1rem 1.5rem;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
}

.nav-menu-btn {
    display: none;
    text-align: center;
    width: 35px;
    height: 35px;
    border: 2px solid black;
    z-index: 10;
}
.nav-close-btn{
    width: 40px;
    height: 40px;
    text-align: center;
}

.nav-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
/* .nav-contact{
    align-self: flex-end;
    padding: 1rem 0;
    font-size: 1.5rem;
} */

.nav-item {
    margin-left: 3.5rem;
}

.nav-link{
    font-size: 1.6rem;
    font-weight: 500;
    /* color: #475569; */
    color: black;
}
#login-btn{
    color:#9A1452;
}
.footer .nav-link{
    color: white!important;
}

.nav-link:hover{
    color: var(--primary-color);
}

.nav-logo {
    align-self: center;
    font-size: 2.1rem;
    font-weight: 500;
    color: var(--primary-color);
}
.backdrop{
    
    display: none;
    position: fixed;
    top:0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background-color: transparent;
    background-color: rgba(94, 94, 94, 0.473);
    -moz-opacity: 0.6;
    /* Mozilla */
    opacity: 0.8;
    z-index: 30;
}
.backdrop.show-overlay{
    display: block;
}
.mobile-nav-menu-overlay{
    /* display: none; */
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    left: 100%;
    /* background-color: white; */
    background-color: transparent;
    z-index: 40;
    transition: ease-out 0.3s;
    overflow-x: hidden;
    display: flex;
}
.mobile-nav-menu-overlay.show-overlay{
    /* width: 60vw; */
    left: 0;
    /* right: 0; */
}
.backdrop-container{
    background-color: transparent;
    width: 60vw;
    z-index: 50;
}
.mobile-nav-menu-container{
    width: 40vw;
    background-color: white;
    z-index: 50;
}

.main{
    /* min-height: calc(100vh - var(--header-height) - var(--footer-height));;
    max-height: auto; */
    width: 100%;
    /* max-width: 992px; */
    flex: 1 0 auto;
    background-color: var(--background-color);
    /* background-color: rgb(238, 238, 238); */
}
.container{
    width: 100%;
    max-width: 992px;
    margin: auto;
    padding: 0 var(--md);
}
.divider{
    width: 100%;
    border-top: 3px solid #460A29;
}
.footer{
    height: var(--footer-height);
    width: 100vw;
    color: white;
    background-color: var(--trendy-pink);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}
.footer .nav-link{
    color: white;
    font-size: 1.3rem;
}

.footer p{
    font-weight: 500;
}
.mobile-footer{
    display: none;
    position: fixed;
    bottom: 0;
    height: 80px;
    width: 100%;
    background-color: white;
    /* background-color: transparent; */
    z-index: 1;
}
.footer-content{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.footer-btn{
    width: 100%;
    height: 80px;
    /* display: grid;
    place-items: center; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
}
.footer-btn>i{
    margin-bottom: 10px;
}
.sized{
    /* background-color: teal; */
    height: 1000px;
}
@media only screen and (max-width: 768px) {
    .container{
        padding-bottom: 80px;
    }
    .mobile-nav-menu {
        position: absolute;
        top: 50px;
        right: 0;
        display: flex;
        flex-direction: column;
        /* justify-content: space-between; */
        align-items: flex-end;
        /* width: 100%; */
        border-radius: 10px;
        text-align: end;
        transition: 0.3s;
        padding-right: 20px;
        overflow: hidden;
    }
    .mobile-nav-menu.close{
        display: none;
    }
    .nav-menu{
        display: none;
    }
    .main{
        padding-bottom: 80px;
    }

    .nav-item {
        height: 60px;
    }
    .nav-close-btn{
        width: auto;
    }
    .nav-menu-btn {
        position: fixed;
        right: 20px;
        top: 50px;
        display: block;
        cursor: pointer;
    }
    .nav-contact{
        display: none;
    }
    .footer{
        display: none;
    }
    .mobile-footer{
        display: block;
    }
}
@media only screen and (min-width: 769px) {
    .mobile-nav-menu-overlay{
        display: none;
    }
}
.content-grid-1{
    width: 100%;
    display: grid;
    /* margin: 5px; */
    padding: 5px;
    grid-template-columns: repeat(auto-fit, minmax(384px, 1fr));
    /* grid-gap: 1rem; */
    grid-row-gap: 2.5rem;
    row-gap: 2.5rem;
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
}
@media only screen and (max-width: 768px) {
    .content-grid-1{
        /* grid-template-columns: repeat(auto-fit, minmax(200px, 384px)); */
    }
}
@media only screen and (max-width: 384px) {
    .content-grid-1{
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
}
/* .content, .word, .pic{
    background-color: teal;
    color: white;
    font-size: 2rem;
    height: 50px;
    max-width: calc(100vw - 10px);
} */


.word{ 
    width: 100%;
    background-color: thistle;
}
.pic{ 
    width: 100%;
    /* background-color:lightblue; */
}
.content-grid-2{
    /* display: flex;
    flex-direction: column; */
    /* padding: 5px; */
    /* gap: 30px; */
}
.content-grid-2-row{
    display: flex;
    margin-bottom: 5px;
    /* gap: 1rem; */
}
@media only screen and (max-width: 768px) {
    .content-grid-2{
        display: flex;
        flex-direction: column;
        /* gap: 50px; */
    }
    .content-grid-2-row{
        flex-direction: column;
    }
    .content-grid-2-row:nth-child(even){
        flex-direction: column-reverse;
    }
}
a:hover{
    cursor: pointer;
}

.banner{
    background-color: #ffffad;
    font-size: 2rem;
    line-height: 1;
    color: #f93416;
    text-align: center;
    padding: 20px
}
.img-title-container{
    background-repeat: no-repeat, repeat;
    background-position: center;
    background-size: cover;
    height: 200px;
    width: 100%;
    display: grid;
    place-items: center;
}
.features .img-title-container{
    position: relative;
    background-image: url("https://static.wixstatic.com/media/nsplsh_e22538ff50a6492aa1ab6013b090f06a~mv2.jpg/v1/fill/w_1164,h_245,al_c,q_80,usm_0.66_1.00_0.01/nsplsh_e22538ff50a6492aa1ab6013b090f06a~mv2.webp");
}
.features .img-title-container:after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.3;
    z-index: 1;
}
.features .img-title-container h1{
    color: white;
    text-align: center;
}
.img-title-wrapper{
    height: 100%;
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px 0 70px 0;
}
.features .img-title-container h1:nth-child(1){
    font-size: 16px;
}
/* .features>h2{
    margin: 4.5rem 0;
    text-align: center;
} */
.features-title{
    margin: 4.5rem 0;
    text-align: center;
    font-size: 24px;
}

.content-grid-2 .word-container{
    /* width: 100%; */
    flex: 430px 0;
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    /* justify-content: center; */
    /* place-items: center; */
}

.content-grid-2 .word-container h2{
    font-size: 20px;
    margin: 0;
    margin-bottom: 20px;
    color: #9A1452;
}
.content-grid-2 .content-grid-2-row{
    margin: 50px 0;
    /* gap: 80px; */
    justify-content: space-between;
}
.content-grid-2 .content-grid-2-row:nth-child(2){
    margin-top: 0;
}
.features .content-grid-2-row .word-container>div{
    width: 80%;
}
@media only screen and (max-width: 768px) {
    .features .content-grid-2-row .word-container>div{
        width: 100%;
    }
    .features .content-grid-2 h2{
       
        margin-bottom: 20px;
        /* margin: 20px 0; */
        text-align: center;
    }
    .features-title{
        margin: 0 0;
        margin-top: 50px;
        /* margin-bottom: 20px; */
    }
    .features .content-grid-2 .content-grid-2-row{
        margin: 20px 0;
        /* justify-content: center; */
    }
    .features .content-grid-2-row .word-container{
        max-height: 270px;
        max-width: 80%;
        align-self: center;
        /* justify-content: center; */
    }
    .features .content-grid-2-row .word-container h2{
        text-align: center;
    }
    .container h2 {
        /* margin-bottom: 10px; */
    }
}
.title-remark{
    font-size: 10px;
    color: black;
}
.list-item{
    display: list-item;                                          
    list-style-type: disc; 
    margin-left: 20px;
}
.image-container, .gif-container, .iphone-container{
    background-repeat: no-repeat, repeat;
    background-position: center;
    background-size: contain;
}
.image-container{
    background-color: var(--background-color);
    flex: 430px 0;
    position: relative;
    height: 328px;
    width: 420px;
}
.ipad-border{
    background-color: rgba(39, 38, 38, 0.938);
    border: rgba(39, 38, 38, 0.938) solid 10px;
    border-radius: 10px;
    box-shadow: 2px 2px 4px 1px rgba(120,120,120,1);
}
.iphone-container{
    margin: auto;
    width: 140px;
    height: 320px;
    background-color: rgba(39, 38, 38, 0.938);
    border: rgba(39, 38, 38, 0.938) solid 8px;
    border-radius: 15px;
    box-shadow: 2px 2px 4px 1px rgba(120,120,120,1);
}
@media only screen and (max-width: 768px) {
    .image-container{
        max-height: 300px;
        width: 400px;
        align-self: center;
    }
    .iphone-container{
        width: 120px;
        height: 260px;
    }
}
@media only screen and (max-width: 600px) {
    .image-container{
        max-height: 230px;
        width: 300px;
    }
}
/* @media only screen and (min-width: 769px) {
    .content-grid-2-row:nth-child(even) .image-container{
        background-position:left;
    }
    .content-grid-2-row:nth-child(even) .word-container{
        justify-content: flex-end;
    }
    .content-grid-2-row:nth-child(odd) .image-container{
        background-position:right;
    }
    .content-grid-2-row:nth-child(odd) .word-container{
        justify-content: flex-start;
    }
} */

#menu{
    background-image: url("https://firebasestorage.googleapis.com/v0/b/caterat-pos.appspot.com/o/%E9%A4%90%E9%A3%B2%E7%B3%BB%E7%B5%B1%E9%9B%BB%E5%AD%90%E9%A4%90%E7%89%8C.png?alt=media&token=d2f0b6a4-3648-4861-b9ba-616cdcf3950e");
}
#floor-plan{
    background-image: url("https://firebasestorage.googleapis.com/v0/b/caterat-pos.appspot.com/o/Ipad.png?alt=media&token=a71e880e-2149-45df-906b-1cfbc8fbdb7a");
}
#floor-plan-gif{
    background-image: url("https://firebasestorage.googleapis.com/v0/b/caterat-pos.appspot.com/o/%E9%A4%90%E9%A3%B2%E7%B3%BB%E7%B5%B1%E5%BA%A7%E4%BD%8D%E5%AE%89%E6%8E%92.gif?alt=media&token=982b3988-2465-4d60-af3b-5f1b172b883a");
}
.pricing .container{
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
}
.pricing .img-title-container{
    position: relative;
    background-image: url(/static/media/pricing_background.6a22f938.webp);
}
.pricing .img-title-container h1{
    color: white;
    text-align: center;
}
.img-title-wrapper{
    height: 100%;
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px 0 70px 0;
}
.pricing .img-title-container h1:nth-child(1){
    font-size: 16px;
}
.plan-btn{
    display: none;
}

.plan-btn div{
    border: 1px solid #9A1452;
    font-size: 2rem;
    line-height: 50px;
    text-align: center;
    height: 50px;
    /* width: 200px; */
}
.pricing-label.first{
    border-top-left-radius: var(--sm);
    border-bottom-left-radius: var(--sm);
}

.pricing-label.last{
    border-top-right-radius: var(--sm);
    border-bottom-right-radius: var(--sm);
}
input[type="radio"] {  
    display:none;                      
}
input[type="radio"] + label span{
    display:inline-block;
    border: 1px solid #9A1452;
    font-size: 2rem;
    line-height: 50px;
    text-align: center;
    height: 50px;
    width: 150px;          
    cursor: pointer;
    /* margin:1px 0px 0 -30px; */
}
input[type="radio"]:checked + label span{
    background-color: #9A1452;
    color: white;
}
.plan-card{
    width:300px;
    min-height: 300px;
    background-color: white;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    /* box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.075); */
    border-radius: var(--sm);
    transition: box-shadow .3s;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    text-align: center;
    grid-gap: var(--md);
    gap: var(--md);
    padding: var(--md);
}
.plan-card h3{
    font-size: 2.2rem;
    line-height: 4rem;
}
.plan-card div{
    flex: 1 1;
}
/* .plan-card:hover{
    box-shadow: 0 10px 20px -2px rgba(0, 0, 0, 0.075)
} */
.plan-card i{
    font-size: 6rem;
}
.plan-card p{
    font-size: 1.6rem;
    line-height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.4rem 0;
}
.plan-card p>i{
    font-size: 1.8rem;
    margin-left: 0.5rem;
    line-height: 2.5rem;
    vertical-align: middle;
}

.plan-card-price h6{
    font-family: "Lato",sans-serif;
    letter-spacing: -2px;
    color: #525252;
    font-weight: 400;
    font-size: 1.4rem;
}

.plan-card .dollar-sign {
    display: inline-block;
    font-family: "Lato",sans-serif;
    font-size: 18px;
    font-weight: 500;
    vertical-align: top;
}
.plan-card .currency {
    font-family: "Lato",sans-serif;
    font-size: 36px;
    font-weight: 500;
    letter-spacing: -2px;
    line-height: 36px;
    padding: 0;
    vertical-align: middle;
}
.plan-card .year, .month, .interval{
    font-family: "Lato",sans-serif;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 3px;
    vertical-align: bottom;
}
.pricing h2{
    color: #9A1452;
}
.plan-card-btn{
    /* margin: 20px 0; */
    margin-top: 5px;
    margin-bottom: 20px;
    height: 50px;
    width: 150px;
    border-radius: var(--sm);
    background-color: #9A1452;
    color: white;
    font-size: 1.8rem;
}
/* .pricing .list-item{
    font-size: 1.875rem;
        line-height: 2.25rem;
} */
.plan-card-container{
    display: flex;
    grid-gap: 5rem;
    gap: 5rem;
    padding: 20px 0;
}
@media only screen and (max-width: 768px) {
    .plan-card-container{
        flex-direction: column;
        grid-gap: 2rem;
        gap: 2rem;
    }
    .plan-btn{
        display: flex;
    }
    .desktop-only{
        display: none;
    }
}
.plan-card .highlight{
    color: #9A1452;
}
.plan-card .remark{
    font-size: 1.2rem;
    cursor: pointer;
    margin-bottom: 0px;
    margin-top: 20px;
    color: grey;
}
.tvp-description{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    /* margin: 20px 0; */
}
.tvp-description a{
    color: #373d4a;
    text-decoration: underline;
}

.tvp-description h2{
    text-align: center;
}
.tvp-description h4{
    text-align: center;
    line-height: 3rem;
}
#pricing-remark{
    text-align: center;
    margin-top: 10px;
}
.space-separator{
    height: 15px;
}
.freedemo .img-title-container{
    position: relative;
    background-image: url(/static/media/freedemo_background.1f7b75b5.webp);
}
.freedemo .img-title-container h1{
    color: white;
    text-align: center;
}
.img-title-wrapper{
    height: 100%;
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px 0 70px 0;
}
.freedemo .img-title-container h1:nth-child(1){
    font-size: 16px;
}
.freedemo .container{
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.freedemo-description{
    margin: 60px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.freedemo h2{
    color: #9A1452;
}
.qr-code-container{
    
}
.qr-code-container h5, 
.qr-code-container p{
    text-align: center;
}

.qr-code-container p{
    font-size: 1.2rem;
}

.qr-code{
    height: 200px;
    width: 200px;
    background-position: center;
    background-size: contain;
}
.qr-code-wrap{
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    padding: 0 40px;
}
@media only screen and (max-width: 767px) {
    .qr-code-wrap{
        display: flex;
        padding: 20px 0;
    }
}
.register-btn{
    margin: 20px 0;
    height: 50px;
    width: 150px;
    border-radius: var(--sm);
    background-color: #b6286a;
    color: white;
    font-size: 1.8rem;
    text-align: center;
    transition: 0.5s;
}
.register-btn:hover{
    background-color: #771543;
    transition: 0.5s;
}
.tutorial-grid{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* display: grid; */
    /* grid-template-columns: 1fr 1fr; */
    /* grid-row: 80vh; */
    /* row-gap: 100px; */
    /* column-gap: 10px;
    align-items: center; */
}
.tutorial-grid .pic img{
    width: 100%;
    height: 700px;
    object-fit: contain;
    /* min-height: 200px; */
    /* vertical-align: middle; */
}
.tutorial-grid .pic{
    /* display: grid;
    place-items: center; */
    margin-bottom: 50px;
    margin-top: 10px;
}

.tutorial-grid .ipad-pic{
    /* height: 500px; */
    width: 660px;
    justify-self: center;
    margin: 40px auto 80px;
}

.tutorial-grid .ipad-pic>img{
    height: 470px;
    width: 640px;
}
.freedemo-wrapper h4{
    margin: 10px 0;
}
.tutorial-grid .txt{
    display: flex;
    justify-self: center;
}
.tutorial-grid .txt>h3{
    margin-right: 12px;
}
.tutorial-grid h3{
    color: #9A1452;
    font-size: 3rem;
    line-height: 4rem;
}
.tutorial-grid p{
    font-size: 1.5rem;
    line-height: 3rem;
}
@media only screen and (max-width: 992px) {
    .tutorial-grid .ipad-pic{
        height: 500px;
        width: 650px;
    }
    .tutorial-grid .pic img{
        height: 600px;
    }
    .tutorial-grid .ipad-pic img{
        height: 100%;
        width: 100%;
    }
}


@media only screen and (max-width: 767px) {
    .tutorial-grid{
        grid-template-columns: 1fr;
        grid-row-gap: 60px;
        row-gap: 60px;
    }
    .tutorial-grid .ipad-pic{
        height: 450px;
        width: 600px;
        /* margin-top: unset; */
    }
    .tutorial-grid .pic{
        height: 450px;
        width: 600px;
    }
    .tutorial-grid .pic img{
        height: 100%;
        width: 100%;
    }
    .tutorial-grid h3{
        font-size: 2.25rem;
        line-height: 3rem;
    }
    .tutorial-grid p{
        font-size: 1.5rem;
        line-height: 2rem;
    }
    .tutorial-grid .txt{
        justify-self: start;
    }
}
@media only screen and (max-width: 600px) {
    .tutorial-grid .ipad-pic{
        width: 300px;
        height: 230px;
    }
    .tutorial-grid .pic{
        height: 260px;
        width: 340px;
    }
    .tutorial-grid .pic img{
        height: 100%;
        width: 100%;
    }
}
.download-on-appstore{
    height: 50px;
    width: 100px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/caterat-pos.appspot.com/o/resources%2Fappstore.svg?alt=media&token=a5e9b5ba-6830-449e-b288-eea378fa3874");
}
.download-on-playstore{
    height: 50px;
    width: 100px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/caterat-pos.appspot.com/o/resources%2Fplaystore.svg?alt=media&token=36de0cf6-fc5c-4205-bf89-d6bc5c1a2514");
}
.button-container{
    width: 100%;
    text-align: center;
}
.content-grid-5{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.contactus{
    height: 100%;
}
.contactus .container{
    padding: 30px;
    /* display: flex;
    align-items: center; */
}
.contactus .container h2{
    font-size: 2.25rem;
}
.address-map{
    width: 100%;
    height: 450px;
}
@media only screen and (max-width: 768px) {
    .content-grid-5{
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 30px;
        row-gap: 30px;
    }
    .contactus .container h2{
        font-size: 1.9rem;
    }
}



